<template>
  <div class="home_top">
    <div class="inner">
      <div class="left_item">
        <div class="left-text" v-html="`Simple loan fast <br>review convenient <br> and fast`" />
        <div>
          <a href="">
            <img src="@/assets/images/loanImg/download_now.png" alt="">
          </a>
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <img src="@/assets/images/loanImg/top_app.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.home_top {
  min-width: 1200px;
  height: 853px;
  background: #fff;
  position: relative;
  .inner {
    width: 1200px;
    margin: 0 auto;
    padding: 41px 0 170px;
    display: flex;
    .left_item {
      margin-top: 192px;
      margin-right: 92px;
      .left-text {
        width: 400px;
        font-size: 40px;
        line-height: 50px;
        color: #E01B4E;
        font-weight: 700;
        margin-bottom: 50px;
      }
      h3 {
        font-size: 36px;
        color: #333333;
        font-weight: 700;
        margin-bottom: 40px;
      }
      p {
        font-size: 16px;
        color: #666666;
        margin-bottom: 100px;
      }
      div {
        img {
          width: 202px;
          height: 59px;
          margin-right: 20px;
        }
      }
    }
    .right_item {
      img {
        width: 634px;
        height: 853px;
      }
    }
  }
}
</style>
