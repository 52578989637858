var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_bottom"},[_c('div',{staticClass:"bg"},[_c('ul',{staticClass:"list"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',{staticClass:"right_item last"},[_c('img',{staticStyle:{"width":"36px","height":"34px"},attrs:{"src":require("@/assets/images/loanImg/icon_bank.png"),"alt":""}}),_c('p',{staticClass:"text1",domProps:{"innerHTML":_vm._s(`Credit will increase your <br>loan amount`)}}),_vm._m(5)])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"left_item"},[_c('img',{staticStyle:{"width":"36px","height":"34px"},attrs:{"src":require("@/assets/images/loanImg/icon_affordable.png"),"alt":""}}),_c('p',{staticClass:"text1"},[_vm._v(" Multiple lenders provide"),_c('br'),_vm._v(" multiple loan products ")]),_c('p',{staticClass:"text2"},[_vm._v(" PesoSafe provides multiple "),_c('br'),_vm._v("lenders provide multiple loan products to "),_c('br'),_vm._v("meet your emergency loan needs. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"right_item"},[_c('img',{staticStyle:{"width":"36px","height":"34px"},attrs:{"src":require("@/assets/images/loanImg/icon_easy.png"),"alt":""}}),_c('p',{staticClass:"text1"},[_vm._v("Provide free credit report")]),_c('p',{staticClass:"text2"},[_vm._v(" SmartCreditScore powers instant"),_c('br'),_vm._v(" decisions for NBFCs, Banks, Wallets and E-Commerce,"),_c('br'),_vm._v("our partnerships with credit"),_c('br'),_vm._v(" information companies Experian and CIBIL, "),_c('br'),_vm._v("respectively, to offer free credit reports, "),_c('br'),_vm._v("made your lending more efficient. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"left_item last"},[_c('img',{staticStyle:{"width":"30px","height":"34px"},attrs:{"src":require("@/assets/images/loanImg/icon_we.png"),"alt":""}}),_c('p',{staticClass:"text1"},[_vm._v("We are fast")]),_c('p',{staticClass:"text2"},[_vm._v(" When credit report is generated,"),_c('br'),_vm._v(" the loan will be received as soon as 1 hour"),_c('br'),_vm._v(" after your application ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"right_item last"},[_c('img',{staticStyle:{"width":"32px","height":"36px"},attrs:{"src":require("@/assets/images/loanImg/icon_me.png"),"alt":""}}),_c('p',{staticClass:"text1"},[_vm._v("Always there for you")]),_c('p',{staticClass:"text2"},[_vm._v(" Lenders on PesoSafe"),_c('br'),_vm._v(" are working online 7*24hours."),_c('br'),_vm._v(" We are always there for you. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"left_item last"},[_c('img',{staticStyle:{"width":"32px","height":"32px"},attrs:{"src":require("@/assets/images/loanImg/icon-simple.png"),"alt":""}}),_c('p',{staticClass:"text1"},[_vm._v("Simple procedure")]),_c('p',{staticClass:"text2"},[_vm._v(" We minimized all the documents to "),_c('br'),_vm._v("provide you the easiest way to get the"),_c('br'),_vm._v("loan. With just an application to fill, no "),_c('br'),_vm._v("collateral and no guarantors. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text2"},[_vm._v(" Repayment on time helps to improve credit."),_c('br'),_vm._v("The lender will raise your"),_c('br'),_vm._v(" loan limit based on credit. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phome"},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/phone.png"),"alt":""}})])
}]

export { render, staticRenderFns }